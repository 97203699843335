var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "title", attrs: { align: "center" } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { md: 12, lg: 12, xl: 12, sm: 12, xs: 24 } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 12, align: "left" } }, [
                      _c("p", { staticClass: "text" }, [_vm._v("・名前 :")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text" }, [
                        _vm._v("・ユーザーID :")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text" }, [_vm._v("・役割 :")])
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12, align: "left" } }, [
                      _c("div", [
                        _c("p", { staticClass: "text_bold" }, [
                          _vm._v(
                            _vm._s(_vm.myUser.last_name) +
                              " " +
                              _vm._s(_vm.myUser.name)
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.myUser.id))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.myUser.roleName))
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              {
                attrs: { md: 6, lg: 6, xl: 6, sm: 12, xs: 24, align: "center" }
              },
              [
                _vm.role.admin_user_view || _vm.admin_user_add
                  ? _c(
                      "div",
                      { staticClass: "syoosai" },
                      [
                        _c("ButtonWidget", {
                          attrs: {
                            width: 100,
                            "background-color": "#F4F4F4",
                            color: "#909399",
                            title: "詳細"
                          },
                          on: { handleClick: _vm.goToDetailUser }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "category-title text", attrs: { height: "40px" } },
          [_vm._v("企業情報")]
        ),
        _vm._v(" "),
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { md: 6, lg: 6, xl: 6, sm: 6, xs: 12, align: "left" } },
              [
                _c("p", { staticClass: "text" }, [_vm._v("・企業名 :")]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [_vm._v("・企業ID :")]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [_vm._v("・参加人数 :")])
              ]
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { md: 6, lg: 6, xl: 6, sm: 6, xs: 12, align: "left" } },
              [
                _c("p", { staticClass: "text_bold" }, [
                  _vm._v(_vm._s(_vm.myUser.companyName))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.myUser.companyID))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.myUser.companyCount) + "名")
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "category-title text", attrs: { height: "40px" } },
          [_vm._v("プラン情報")]
        ),
        _vm._v(" "),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { align: "left" } }, [
              _c("p", { staticClass: "text" }, [
                _vm._v("・現在のプラン : "),
                _c("label", { staticClass: "text_bold" }, [
                  _vm._v(_vm._s(_vm.myUser.planName))
                ])
              ]),
              _vm._v(" "),
              _vm.role.admin_role_view
                ? _c("p", { staticClass: "text" }, [
                    _vm._v("・各役割 : "),
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        on: { click: _vm.goToDetailPermission }
                      },
                      [_vm._v("詳細")]
                    )
                  ])
                : _vm._e()
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "category-title text" }, [_vm._v("サポート")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm._m(0)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "service" }, [
      _c("div", { staticClass: "text" }, [
        _vm._v("サポートへのお問い合わせは"),
        _c(
          "a",
          {
            staticClass: "link",
            attrs: {
              href: "https://www.daydelight.com/contact",
              target: "_blank"
            }
          },
          [_vm._v("「サポートへのお問い合わせ」")]
        ),
        _vm._v("よりお問い合わせください")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    title: {
      type: String,
      default: '',
      require: true
    },
    width: {
      type: [Number, String],
      default: null,
      require: false
    },
    isLoading: {
      type: Boolean,
      default: false,
      require: false
    },
    isDisabled: {
      type: Boolean,
      default: false,
      require: false
    },
    backgroundColor: {
      type: String,
      default: '#0B5BAC',
      require: false
    },
    color: {
      type: String,
      default: '#fff',
      require: false
    },
    className: {
      type: String,
      default: '',
      require: false
    }
  }
};
exports.default = _default;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background" }, [
    _c(
      "div",
      { staticStyle: { padding: "0 20px" } },
      [
        _c(
          "el-row",
          { attrs: { gutter: 8, justify: "end" } },
          [
            _c(
              "el-col",
              {
                class: _vm.device.mobile ? "space" : "",
                attrs: { md: 4, lg: 4, xl: 4, sm: 12, xs: 24 }
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { size: "small", placeholder: "役割で検索" },
                    model: {
                      value: _vm.form.roleSearch,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "roleSearch", $$v)
                      },
                      expression: "form.roleSearch"
                    }
                  },
                  _vm._l(_vm.userRoles, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              {
                class: _vm.device.mobile ? "space" : "",
                attrs: { md: 4, lg: 4, xl: 4, sm: 12, xs: 24 }
              },
              [
                _c("el-input", {
                  attrs: { size: "small", placeholder: "フリー検索" },
                  model: {
                    value: _vm.searching,
                    callback: function($$v) {
                      _vm.searching = $$v
                    },
                    expression: "searching"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              {
                class: _vm.device.mobile ? "space" : "",
                attrs: {
                  md: _vm.titleSize,
                  lg: _vm.titleSize,
                  xl: _vm.titleSize,
                  sm: 12,
                  xs: 24
                }
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: { click: _vm.swapComponent }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("truncate")(
                          _vm.title,
                          _vm.device.mobile ? 24 : 40,
                          "..."
                        )
                      )
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-drawer",
              {
                attrs: {
                  visible: _vm.drawer,
                  "with-header": false,
                  size: _vm.device.mobile ? "80%" : "auto"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.drawer = $event
                  }
                }
              },
              [
                _c("AffiliationGroup", {
                  on: { panretToggleDrawer: _vm.swapComponent }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-col",
                  {
                    attrs: {
                      md: 2,
                      lg: 2,
                      xl: 2,
                      sm: 12,
                      xs: 24,
                      align: "center"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "colorButton",
                        staticStyle: { width: "100px" },
                        attrs: { size: "small", plain: "" },
                        on: { click: _vm.search }
                      },
                      [_vm._v("検索")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    attrs: {
                      md: 2,
                      lg: 2,
                      xl: 2,
                      sm: 12,
                      xs: 24,
                      align: "center"
                    }
                  },
                  [
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#0b5bac", "font-size": "14px" }
                        },
                        [_vm._v("検索結果")]
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(
                          "\n              " + _vm._s(_vm.searchUserCount)
                        ),
                        _c("span", { staticStyle: { "font-size": "12px" } }, [
                          _vm._v("件")
                        ])
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c("ButtonPagination", {
          attrs: { "page-count": _vm.currentPage, "page-total": _vm.maxPage },
          on: {
            goTopPage: function($event) {
              return _vm.goTopPage()
            },
            goNextPage: function($event) {
              return _vm.goNextPage()
            },
            goPrePage: function($event) {
              return _vm.goPrePage()
            },
            goLastPage: function($event) {
              return _vm.goLastPage()
            }
          }
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: { data: _vm.coms, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { label: "社員名", align: "center", width: "160" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "span",
                        [
                          _vm._v(_vm._s(scope.row.name) + "\n            "),
                          !scope.row.is_display
                            ? _c("svg-icon", {
                                attrs: { "icon-class": "incognito-off" }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "所属", align: "center", "min-width": "240" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("truncate")(
                                scope.row.userAffiliation,
                                60,
                                "..."
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "position",
                label: "役職",
                align: "center",
                width: "160"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "役割", align: "center", width: "140" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.role.name) + "\n            "),
                        scope.row.hasRoleChatCount
                          ? _c("i", { staticClass: "el-icon-lock" })
                          : _vm._e()
                      ])
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "id",
                label: "ユーザーID",
                align: "center",
                width: "130"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              staticClass: "buttons",
              attrs: { align: "center", width: "130" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-first colorButton",
                          attrs: { size: "small", type: "info", plain: "" },
                          on: {
                            click: function($event) {
                              return _vm.goToDetail(scope.row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.role.admin_user_edit ? "編集" : "詳細")
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("ButtonPagination", {
          attrs: { "page-count": _vm.currentPage, "page-total": _vm.maxPage },
          on: {
            goTopPage: function($event) {
              return _vm.goTopPage()
            },
            goNextPage: function($event) {
              return _vm.goNextPage()
            },
            goPrePage: function($event) {
              return _vm.goPrePage()
            },
            goLastPage: function($event) {
              return _vm.goLastPage()
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("br")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
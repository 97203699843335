var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buttonWidget" },
    [
      _c(
        "el-button",
        {
          class: "" + _vm.className,
          style:
            "width: " +
            (_vm.$device.mobile ? "100%" : _vm.width + "px") +
            "; background-color: " +
            (_vm.isDisabled ? "#c8c9cc" : _vm.backgroundColor) +
            "; color: " +
            _vm.color +
            ";",
          attrs: {
            size: "small",
            disabled: _vm.isDisabled,
            loading: _vm.isLoading
          },
          on: {
            click: function($event) {
              return _vm.$emit("handleClick")
            }
          }
        },
        [_vm._v(_vm._s(_vm.title))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.setToken = setToken;
exports.removeToken = removeToken;
exports.setCompanyCode = setCompanyCode;
exports.getCompanyCode = getCompanyCode;
exports.removeCompanyCode = removeCompanyCode;
exports.setPerCompanyCode = setPerCompanyCode;
exports.getPerCompanyCode = getPerCompanyCode;
exports.removePerCompanyCode = removePerCompanyCode;
exports.setEventDetailCode = setEventDetailCode;
exports.getEventDetailCode = getEventDetailCode;
exports.removeEventDetailCode = removeEventDetailCode;
exports.setEditCompanyCode = setEditCompanyCode;
exports.getEditCompanyCode = getEditCompanyCode;
exports.removeEditCompanyCode = removeEditCompanyCode;
exports.setPerEventCode = setPerEventCode;
exports.getPerEventCode = getPerEventCode;
exports.removePerEventCode = removePerEventCode;
exports.setCouponCode = setCouponCode;
exports.getCouponCode = getCouponCode;
exports.removeCouponCode = removeCouponCode;
exports.setCouponCompanyCode = setCouponCompanyCode;
exports.getCouponCompanyCode = getCouponCompanyCode;
exports.removeCouponCompanyCode = removeCouponCompanyCode;
exports.setCouponEventCode = setCouponEventCode;
exports.getCouponEventCode = getCouponEventCode;
exports.removeCouponEventCode = removeCouponEventCode;
exports.setCouponIndexCode = setCouponIndexCode;
exports.getCouponIndexCode = getCouponIndexCode;
exports.removeCouponIndexCode = removeCouponIndexCode;
exports.setUserUID = setUserUID;
exports.getUserUID = getUserUID;
exports.removeCompanyUID = removeCompanyUID;
exports.removeSidebarStatus = removeSidebarStatus;
exports.removeSize = removeSize;
exports.setZoneId = setZoneId;
exports.getZoneId = getZoneId;
exports.setHistoryDataFromPage = setHistoryDataFromPage;
exports.getHistoryDataFromPage = getHistoryDataFromPage;
exports.setIdEditPage = setIdEditPage;
exports.getIdEditPage = getIdEditPage;
exports.setUserPermission = setUserPermission;
exports.getUserPermission = getUserPermission;
exports.setRoles = setRoles;
exports.getRoles = getRoles;
exports.setGoFromPage = setGoFromPage;
exports.getGoFromPage = getGoFromPage;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = 'Admin-Token';

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}

function setCompanyCode(code) {
  return _jsCookie.default.set('CompanyCode', code);
}

function getCompanyCode() {
  return _jsCookie.default.get('CompanyCode');
}

function removeCompanyCode() {
  return _jsCookie.default.remove('CompanyCode');
}

function setPerCompanyCode(code) {
  return _jsCookie.default.set('PerCompanyCode', code);
}

function getPerCompanyCode() {
  return _jsCookie.default.get('PerCompanyCode');
}

function removePerCompanyCode() {
  return _jsCookie.default.remove('PerCompanyCode');
}

function setEventDetailCode(code) {
  return _jsCookie.default.set('EventDetailCode', code);
}

function getEventDetailCode() {
  return _jsCookie.default.get('EventDetailCode');
}

function removeEventDetailCode() {
  return _jsCookie.default.remove('EventDetailCode');
}

function setEditCompanyCode(code) {
  return _jsCookie.default.set('EditCompanyCode', code);
}

function getEditCompanyCode() {
  return _jsCookie.default.get('EditCompanyCode');
}

function removeEditCompanyCode() {
  return _jsCookie.default.remove('EditCompanyCode');
}

function setPerEventCode(code) {
  return _jsCookie.default.set('PerEventCode', code);
}

function getPerEventCode() {
  return _jsCookie.default.get('PerEventCode');
}

function removePerEventCode() {
  return _jsCookie.default.remove('PerEventCode');
}

function setCouponCode(code) {
  return _jsCookie.default.set('CouponCode', code);
}

function getCouponCode() {
  return _jsCookie.default.get('CouponCode');
}

function removeCouponCode() {
  return _jsCookie.default.remove('CouponCode');
}

function setCouponCompanyCode(code) {
  return _jsCookie.default.set('CouponCompanyCode', code);
}

function getCouponCompanyCode() {
  return _jsCookie.default.get('CouponCompanyCode');
}

function removeCouponCompanyCode() {
  return _jsCookie.default.remove('CouponCompanyCode');
}

function setCouponEventCode(code) {
  return _jsCookie.default.set('CouponEventCode', code);
}

function getCouponEventCode() {
  return _jsCookie.default.get('CouponEventCode');
}

function removeCouponEventCode() {
  return _jsCookie.default.remove('CouponEventCode');
}

function setCouponIndexCode(code) {
  return _jsCookie.default.set('CouponIndexCode', code);
}

function getCouponIndexCode() {
  return _jsCookie.default.get('CouponIndexCode');
}

function removeCouponIndexCode() {
  return _jsCookie.default.remove('CouponIndexCode');
}

function setUserUID(code) {
  return _jsCookie.default.set('UserUID', code);
}

function getUserUID() {
  return _jsCookie.default.get('UserUID');
}

function removeCompanyUID() {
  return _jsCookie.default.remove('CompanyUID');
}

function removeSidebarStatus() {
  return _jsCookie.default.remove('sidebarStatus');
}

function removeSize() {
  return _jsCookie.default.remove('sidebarStatus');
}

function setZoneId(zoneId) {
  return _jsCookie.default.set('zoneId', zoneId);
}

function getZoneId() {
  return _jsCookie.default.get('zoneId');
}

function setHistoryDataFromPage(form) {
  return localStorage.setItem('dataFormPage', form);
}

function getHistoryDataFromPage() {
  return localStorage.getItem('dataFormPage');
}

function setIdEditPage(item) {
  return _jsCookie.default.set('idBulletinEditPage', item);
}

function getIdEditPage() {
  return _jsCookie.default.get('idBulletinEditPage');
}

function setUserPermission(user) {
  return localStorage.setItem('userRights', user);
}

function getUserPermission() {
  return localStorage.getItem('userRights');
}

function setRoles(role) {
  return localStorage.setItem('role', role);
}

function getRoles() {
  return localStorage.getItem('role');
}

function setGoFromPage(path) {
  return _jsCookie.default.set('GoFromPage', path);
}

function getGoFromPage() {
  return _jsCookie.default.get('GoFromPage');
}
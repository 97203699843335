"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    title: {
      type: String,
      default: '',
      require: true
    },
    isShowBackButton: {
      type: Boolean,
      default: false,
      require: false
    },
    isUseBackHandmade: {
      type: Boolean,
      default: false,
      require: false
    }
  },
  destroyed: function destroyed() {},
  methods: {}
};
exports.default = _default;
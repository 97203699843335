var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "titleWidget" }, [
    _c(
      "div",
      { staticStyle: { margin: "14px 0" } },
      [
        _vm.isShowBackButton
          ? _c(
              "el-button",
              {
                staticClass: "button_bright_green",
                staticStyle: { "margin-right": "8px" },
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    _vm.isUseBackHandmade
                      ? _vm.$emit("handleBackButton")
                      : _vm.$router.go(-1)
                  }
                }
              },
              [_vm._v("戻る")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "title", staticStyle: { color: "#B8B5B7" } },
          [_vm._v(_vm._s(_vm.title))]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "divider" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
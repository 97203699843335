var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "background" },
    [
      _c(
        "div",
        { staticClass: "createBT" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "info", plain: "" },
              on: {
                click: function($event) {
                  return _vm.handleOpenDialog(true)
                }
              }
            },
            [_vm._v("カテゴリーを追加")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              attrs: {
                id: "tableSuggestionCategories",
                border: "",
                data: _vm.bulletinCategories
              }
            },
            [
              _c("el-table-column", {
                staticClass: "buttons",
                attrs: {
                  align: "center",
                  "min-width": "220px",
                  label: "カテゴリーリスト"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { attrs: { align: "left" } }, [
                          _vm._v(_vm._s(scope.row.name))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _vm.role.admin_bulletin_category_edit
                ? _c("el-table-column", {
                    staticClass: "buttons",
                    attrs: { align: "center", width: "120px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "button-first colorButton",
                                  attrs: {
                                    size: "small",
                                    type: "info",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleOpenDialog(
                                        false,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [_vm._v("編集")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      281888865
                    )
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.form.titleDialog,
                visible: _vm.enableDialogVisible,
                width: "380px",
                center: ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.enableDialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    {
                      key: _vm.category_key,
                      attrs: {
                        prop: "inputDialog",
                        rules: {
                          required: false,
                          validator: _vm.validateMaxLength,
                          colname: "カテゴリー名",
                          maxLen: 20
                        }
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "カテゴリー名" },
                        model: {
                          value: _vm.form.inputDialog,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "inputDialog", $$v)
                          },
                          expression: "form.inputDialog"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-row",
                    { attrs: { md: 5, lg: 5, xl: 5, sm: 5, xs: 1 } },
                    [
                      _c("el-col", { attrs: { span: 8, align: "center" } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c("Button", {
                              attrs: { title: "キャンセル", width: "100" },
                              on: {
                                handleClick: function($event) {
                                  _vm.enableDialogVisible = false
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8, align: "center" } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c("Button", {
                              attrs: {
                                title: _vm.enableCreate ? "追加" : "更新",
                                width: "100",
                                "is-disabled": _vm.disableButton
                              },
                              on: {
                                handleClick: function($event) {
                                  return _vm.handleDialogValidate()
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      !_vm.enableCreate
                        ? _c(
                            "el-col",
                            { attrs: { span: 8, align: "center" } },
                            [
                              _c(
                                "div",
                                { staticClass: "grid-content bg-purple" },
                                [
                                  _c("Button", {
                                    attrs: {
                                      title: "削除",
                                      width: "100",
                                      "class-name": "delete_button",
                                      "background-color": "rgb(237, 110, 110)"
                                    },
                                    on: {
                                      handleClick: function($event) {
                                        return _vm.handleDeleteItem()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
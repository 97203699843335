"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_SUGGESTION_COMMENTS = exports.GET_SUGGESTION_CATEGORIES = exports.GET_SUGGESTION_BY_ID = exports.GET_SUGGESTION = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery suggestion_comments($suggestion_id: Int!, $limit: Int) {\n  suggestion_comments(where: {suggestion_id: {_eq: $suggestion_id}}, order_by: {created_at: desc}, limit: $limit) {\n    id\n    comment\n    created_at\n    updated_at\n    user {\n      uid\n      get_avatar_thumbnail\n      name\n      last_name\n    }\n  }\n  suggestion_comments_aggregate(where: {suggestion_id: {_eq: $suggestion_id}}, limit: null) {\n    aggregate {\n      count\n    }\n  }\n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getSuggestionCategories {\n  suggestionCategories: suggestion_categories(order_by: {created_at: desc}) {\n     id\n     name\n   }\n }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery suggestionQueryById($id: Int!) {\n  suggestion: suggestion_by_pk(id: $id) {\n    content\n    title\n    created_at\n    author_id\n    publish_at\n    show_identity\n    id\n    category_id\n    attach_files(where: {suggestion_id: {_eq: $id}}, order_by: {created_at: desc}) {\n      file_name\n      attach_file_type\n      url\n    }\n    user {\n      name\n      last_name\n    }\n    category {\n      name\n    }\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getSuggestions($keyword: String, $page_limit: Int, $page_offset: Int, $categoryId: Int) {\n  suggestions: search_suggestion_admin_site(args: {page_limit: $page_limit, page_offset: $page_offset, keyword: $keyword, categoryid: $categoryId}, order_by: {publish_at: desc}) {\n    content\n    title\n    publish_at\n    created_at\n    is_viewed\n    show_identity\n    id\n    category {\n      name\n    }\n    user {\n      name\n      last_name\n    }\n  }\n  suggestions_count: search_suggestion_admin_site_aggregate(args: {keyword: $keyword, categoryid: $categoryId, page_limit: null, page_offset: null}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var GET_SUGGESTION = (0, _graphqlTag.default)(_templateObject());
exports.GET_SUGGESTION = GET_SUGGESTION;
var GET_SUGGESTION_BY_ID = (0, _graphqlTag.default)(_templateObject2());
exports.GET_SUGGESTION_BY_ID = GET_SUGGESTION_BY_ID;
var GET_SUGGESTION_CATEGORIES = (0, _graphqlTag.default)(_templateObject3());
exports.GET_SUGGESTION_CATEGORIES = GET_SUGGESTION_CATEGORIES;
var GET_SUGGESTION_COMMENTS = (0, _graphqlTag.default)(_templateObject4());
exports.GET_SUGGESTION_COMMENTS = GET_SUGGESTION_COMMENTS;
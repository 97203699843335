"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_ROLE_DEFAULT = exports.GET_PLAN_USER = exports.GET_USER_COUNT_BY_ROLE_ID = exports.GET_USER_BY_UID = exports.GET_ROLE_BY_ID = exports.GET_ROLES = exports.USER_AFFILIATION_LIST = exports.AFFILIATION_LIST = exports.INDUSTRY_MIDDLE_INFO = exports.INDUSTRY_MIDDLE_LIST = exports.INDUSTRY_MAJOR_LIST = exports.SEARCH_MEMBERS_WITH_AFFILIATION = exports.GET_INDUSTRIES = exports.GET_MEMBER_ALL = exports.GET_MEMBER = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getRoleDefault {\n  role:roles(where: {is_default_role: {_eq: true}}) {\n    id\n    name\n  }\n}\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getPlanUser($zone_id: String!) {\n  planUser: zone_by_pk(id: $zone_id) {\n    plan {\n      max_user\n      name\n    }\n    warning_when_reach_max_user\n  }\n  user_count:user_aggregate(where: {is_active: {_eq: true}, zone_id: {_eq: $zone_id}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getUserCountByRoleId($role_id: Int!) {\n  user_count:user_aggregate(where: {role_id: {_eq: $role_id}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getUserById($uid: uuid!) {\n  user:user_by_pk(uid: $uid) {\n    role {\n      admin_bulletin_add\n      admin_bulletin_category_edit\n      admin_bulletin_edit\n      admin_bulletin_view\n      admin_role_add\n      admin_role_edit\n      admin_role_view\n      admin_user_add\n      admin_user_edit\n      admin_user_import\n      admin_user_view\n      admin_suggestion_view\n      get_can_use_admin_site\n      admin_suggestion_category_edit\n    }\n  }\n}\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getRoleById($id: Int!) {\n  role: roles_by_pk(id: $id) {\n    name\n    app_map\n    app_email\n    app_make_call\n    app_suggestion\n    app_bulletin_board\n    app_chat_read_view\n    app_custom_app_icon\n    app_group_chat_create\n    app_private_chat_create\n    app_affiliation_direct_chat\n    app_view_private_survey_result\n    app_join_affiliation_chat_myself\n    app_allow_chat_image\n    app_allow_chat_file\n    app_allow_suggestion_image\n    app_allow_suggestion_file\n\n    admin_role_add\n    admin_user_add\n    admin_role_edit\n    admin_role_view\n    admin_user_edit\n    admin_user_view\n    admin_user_import\n    admin_bulletin_add\n    admin_bulletin_edit\n    admin_bulletin_view\n    admin_suggestion_view\n    admin_bulletin_category_edit\n    admin_suggestion_category_edit\n    use_admin_site\n  }\n}\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getRoles($zone_id: String!) {\n  roles(order_by: {id: asc}, where: {zone_id: {_eq: $zone_id}}) {\n    name\n    id\n    allow_extend_role\n    allow_ignore_blocking_chat\n    is_default_role\n  }\n}\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getUserAffiliation {\n  user_affiliation {\n    affiliation_id\n    user_id\n  }\n}\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getAffiliation {\n  affiliation(where: {parent_id: {_is_null: true}, is_display: {_eq: true}}, order_by: {order: asc}) {\n    ...affiliationFragment\n    affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n      ...affiliationFragment\n      affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n        ...affiliationFragment\n        affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n          ...affiliationFragment\n          affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n            ...affiliationFragment\n            affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n              ...affiliationFragment\n              affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n                ...affiliationFragment\n                affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n                  ...affiliationFragment\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment affiliationFragment on affiliation {\n  id\n  name\n  parent_id\n  is_main\n}\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery($code: String ) {\n  industries(where: {code: {_eq: $code}}, order_by: {id: asc}) {\n    id\n    name\n    code\n    group_id\n  }\n}\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery($group_id: Int ) {\n  industries(where: {group_id: {_eq: $group_id}}, order_by: {code: asc}) {\n    code\n    name\n  }\n}\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  industry_group(order_by: {id: asc}) {\n    id\n    name\n  }\n}\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery ($search_word: String, $group_industry_id: Int, $industry_code: String, $page_top: Int, $page_size: Int, $affiliations: String, $roleId: Int, $is_active_only: Boolean) {\n  search_user_admin_site: search_user_admin_site_v2(args: {search_word: $search_word, group_industry_id: $group_industry_id, industry_code: $industry_code, page_top: $page_top, page_size: $page_size, affiliations: $affiliations, roleid: $roleId, is_active_only: $is_active_only}) {\n    id\n    uid\n    frist_name: name\n    frist_name_kana: name_furigana\n    last_name: last_name\n    last_name_kana: last_name_furigana\n    is_active\n    position\n    is_display\n    user_role_chat_count:user_role_chat_policies_aggregate(where: {is_active: {_eq: true}}) {\n      aggregate {\n        count\n      }\n    }\n    company {\n      company_name: name\n      industries {\n        group_id\n        code\n        industry_name: name\n      }\n    }\n    user_affiliations(where: {affiliation: {is_display: {_eq: true}}}) {\n      affiliation_id\n      user_id\n      affiliation {\n        name\n        parent_id\n        is_main\n      }\n    }\n    role {\n      name\n    }\n  }\n  search_user_admin_site_aggregate:search_user_admin_site_v2_aggregate(args: {search_word: $search_word, group_industry_id: $group_industry_id, industry_code: $industry_code, affiliations: $affiliations, page_top: null, page_size: null, roleid: $roleId, is_active_only:$is_active_only }) {\n    aggregate {\n      count\n    }\n  }\n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  industries{\n    code\n  }\n}\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  user {\n    id\n    uid\n    employee_id\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nquery getMyInformation($uid: uuid!) {\n  user:user_by_pk(uid: $uid) {\n     avatar\n    company_id\n    last_name\n    last_name_furigana\n    name\n    name_furigana\n    position\n    others\n    employee_id\n    mobile_phone\n    uid\n    id\n    password\n    is_display\n    is_active\n    role_id\n    show_company_info\n    user_policy:user_role_chat_policies {\n      role_id_block\n      is_active\n    }\n    company {\n      id\n      area\n      website\n      business_descriptions\n      capital_stock\n      created_at\n      email\n      employees\n      established\n      employees_part_time\n      fax\n      name\n      name_furigana\n      address_1\n      address_2\n      phone_number\n      join_date\n      mobile_phone\n      postcode\n      industry\n      industries {\n        id\n        name\n        code\n        group_id\n      }\n    }\n    user_affiliations(where: {affiliation: {is_display: {_eq: true}}, is_directly: {_eq: true}}) {\n      is_directly\n      affiliation_id\n      affiliation {\n        id\n        name\n        parent_id\n      }\n    }\n  }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var GET_MEMBER = (0, _graphqlTag.default)(_templateObject());
exports.GET_MEMBER = GET_MEMBER;
var GET_MEMBER_ALL = (0, _graphqlTag.default)(_templateObject2());
exports.GET_MEMBER_ALL = GET_MEMBER_ALL;
var GET_INDUSTRIES = (0, _graphqlTag.default)(_templateObject3());
exports.GET_INDUSTRIES = GET_INDUSTRIES;
var SEARCH_MEMBERS_WITH_AFFILIATION = (0, _graphqlTag.default)(_templateObject4());
exports.SEARCH_MEMBERS_WITH_AFFILIATION = SEARCH_MEMBERS_WITH_AFFILIATION;
var INDUSTRY_MAJOR_LIST = (0, _graphqlTag.default)(_templateObject5());
exports.INDUSTRY_MAJOR_LIST = INDUSTRY_MAJOR_LIST;
var INDUSTRY_MIDDLE_LIST = (0, _graphqlTag.default)(_templateObject6());
exports.INDUSTRY_MIDDLE_LIST = INDUSTRY_MIDDLE_LIST;
var INDUSTRY_MIDDLE_INFO = (0, _graphqlTag.default)(_templateObject7());
exports.INDUSTRY_MIDDLE_INFO = INDUSTRY_MIDDLE_INFO;
var AFFILIATION_LIST = (0, _graphqlTag.default)(_templateObject8());
exports.AFFILIATION_LIST = AFFILIATION_LIST;
var USER_AFFILIATION_LIST = (0, _graphqlTag.default)(_templateObject9());
exports.USER_AFFILIATION_LIST = USER_AFFILIATION_LIST;
var GET_ROLES = (0, _graphqlTag.default)(_templateObject10());
exports.GET_ROLES = GET_ROLES;
var GET_ROLE_BY_ID = (0, _graphqlTag.default)(_templateObject11());
exports.GET_ROLE_BY_ID = GET_ROLE_BY_ID;
var GET_USER_BY_UID = (0, _graphqlTag.default)(_templateObject12());
exports.GET_USER_BY_UID = GET_USER_BY_UID;
var GET_USER_COUNT_BY_ROLE_ID = (0, _graphqlTag.default)(_templateObject13());
exports.GET_USER_COUNT_BY_ROLE_ID = GET_USER_COUNT_BY_ROLE_ID;
var GET_PLAN_USER = (0, _graphqlTag.default)(_templateObject14());
exports.GET_PLAN_USER = GET_PLAN_USER;
var GET_ROLE_DEFAULT = (0, _graphqlTag.default)(_templateObject15());
exports.GET_ROLE_DEFAULT = GET_ROLE_DEFAULT;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c("el-col", { attrs: { span: 20 } }, [
            _c("div", { staticClass: "companyName" }, [
              _vm._v(_vm._s(_vm.myUser.companyName) + "管理サイト")
            ])
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 4 } }, [
            _c(
              "div",
              { staticClass: "right-menu" },
              [
                _vm.device !== "mobile"
                  ? [
                      _c("screenfull", {
                        staticClass: "right-menu-item hover-effect",
                        staticStyle: { "padding-top": "14px" },
                        attrs: { id: "screenfull" }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-dropdown",
                  {
                    staticClass:
                      "avatar-container right-menu-item hover-effect",
                    attrs: { trigger: "click" }
                  },
                  [
                    _c("div", { staticClass: "avatar-wrapper" }, [
                      _c("img", {
                        staticClass: "user-avatar",
                        attrs: {
                          src: _vm.getMyAvatar
                            ? _vm.getMyAvatar
                            : "parson_no-image.png",
                          width: "120",
                          height: "120"
                        }
                      }),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-caret-bottom" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c("span", { on: { click: _vm.logout } }, [
                            _vm._v("ログアウト")
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    staticClass: "dialog",
                    attrs: {
                      title: "報告",
                      visible: _vm.waitDialogVisible,
                      width: "380px",
                      "close-on-click-modal": false,
                      "show-close": false,
                      center: ""
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.waitDialogVisible = $event
                      }
                    }
                  },
                  [
                    _c("center", [_vm._v("あなたの役割が変更されました。")]),
                    _vm._v(" "),
                    _c("center", [_vm._v("ログアウトが強制されています！")])
                  ],
                  1
                )
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
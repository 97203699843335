var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "background" },
      [
        _c(
          "el-row",
          {
            staticStyle: { padding: "0 18px" },
            attrs: { gutter: 8, justify: "end" }
          },
          [
            _c(
              "el-col",
              {
                staticClass: "space",
                attrs: { md: 6, lg: 6, xl: 6, sm: 6, xs: 24 }
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { size: "small", placeholder: "カテゴリーを選択" },
                    model: {
                      value: _vm.category_id,
                      callback: function($$v) {
                        _vm.category_id = $$v
                      },
                      expression: "category_id"
                    }
                  },
                  _vm._l(_vm.suggestionCategories, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              {
                staticClass: "space",
                attrs: { md: 6, lg: 6, xl: 6, sm: 6, xs: 24 }
              },
              [
                _c("el-input", {
                  attrs: { size: "small", placeholder: "タイトル・内容検索" },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { md: 3, lg: 3, xl: 3, sm: 3, xs: 24 } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "colorButton",
                    staticStyle: {
                      "margin-right": "30px !important",
                      width: "100px"
                    },
                    attrs: { size: "small" },
                    on: { click: _vm.handleSearch }
                  },
                  [_vm._v("検索")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-main",
          [
            _c("ButtonPagination", {
              attrs: {
                "page-count": _vm.currentPage,
                "page-total": _vm.maxPage
              },
              on: {
                goTopPage: function($event) {
                  return _vm.goTopPage()
                },
                goNextPage: function($event) {
                  return _vm.goNextPage()
                },
                goPrePage: function($event) {
                  return _vm.goPrePage()
                },
                goLastPage: function($event) {
                  return _vm.goLastPage()
                }
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  border: "",
                  data: _vm.suggestions,
                  "row-class-name": _vm.tableRowClassName
                }
              },
              [
                _c("el-table-column", {
                  attrs: { width: "240", align: "center", label: "タイトル" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("truncate")(scope.row.title, 60, "...")
                                ) +
                                "\n            "
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "240", align: "center", label: "内容" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("truncate")(
                                    scope.row.content,
                                    60,
                                    "..."
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "130",
                    align: "center",
                    label: "カテゴリー",
                    prop: "category"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "130", align: "center", label: "投稿者" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.show_identity
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("truncate")(
                                        scope.row.name,
                                        60,
                                        "..."
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "130",
                    align: "center",
                    label: "投稿日",
                    prop: "created_at"
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "buttons",
                  attrs: { align: "center", width: "130" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "button-first colorButton",
                              attrs: {
                                size: "small",
                                type: scope.row.is_viewed ? "info" : "danger",
                                plain: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.goToDetail(scope.row)
                                }
                              }
                            },
                            [_vm._v("\n              詳細")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("ButtonPagination", {
              attrs: {
                "page-count": _vm.currentPage,
                "page-total": _vm.maxPage
              },
              on: {
                goTopPage: function($event) {
                  return _vm.goTopPage()
                },
                goNextPage: function($event) {
                  return _vm.goNextPage()
                },
                goPrePage: function($event) {
                  return _vm.goPrePage()
                },
                goLastPage: function($event) {
                  return _vm.goLastPage()
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
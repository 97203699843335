"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DELETE_CATEGORY_AND_UPDATE_BULLETIN_CATEGORY_BY_ID = exports.INSERT_BULLETIN_SURVEY_ONE = exports.INSERT_SURVEY_ONE = exports.UPDATE_BULLETIN_CATEGORIES_BY_ID = exports.ADD_BULLETIN_CATEGORIES = exports.DELETE_SURVEY = exports.UPDATE_BULLETIN_BY_ID = exports.UPDATE_BULLETIN = exports.ADD_BULLETIN = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteCategoryAndUpdateBulletinCategory($category_id: Int) {\n  update_bulletin(where: {category_id: {_eq: $category_id}}, _set: {category_id: null}) {\n    affected_rows\n  }\n  delete_bulletin_categories(where: {id: {_eq: $category_id}}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation insertBulletinSurveyOne($bulletin_id: Int!, $survey_id: Int!) {\n  insert_bulletin_survey_one(object: {bulletin_id: $bulletin_id, survey_id: $survey_id}) {\n    id\n  }\n}\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation insertSurveyOne($choice_type: Int!, $question: String!, $data: [survey_answer_insert_input!]!, $is_public_result: Boolean!) {\n  insert_survey_one(object: {choice_type: $choice_type, question: $question, survey_answers: {data: $data}, is_public_result: $is_public_result}) {\n    id\n  }\n}\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation update_bulletin_categories($id: Int!, $name: String!) {\n  update_bulletin_categories_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {\n    id\n  }\n}\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation insertBulletinCategories($name: String!) {\n  insert_bulletin_categories_one(object: {name: $name}) {\n    id\n  }\n}\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteSurveyMutation($survey_id: [Int!]!, $survey_answer_id: [Int!]!) {\n  delete_survey_answer_user(where: {survey_answer_id: {_in: $survey_answer_id}}) {\n    affected_rows\n  }\n  delete_survey_answer(where: {survey_id: {_in: $survey_id}}) {\n    affected_rows\n  }\n  delete_bulletin_survey(where: {survey_id: {_in: $survey_id}}) {\n    affected_rows\n  }\n  delete_survey(where: {id: {_in: $survey_id}}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation updateBulletinMutation($bulletinId: Int!) {\n  update_bulletin_by_pk(pk_columns: {id: $bulletinId}, _set: {deleted_at: \"now()\"}) {\n    id\n  }\n  delete_bulletin_files(where: {bulletin_id: {_eq: $bulletinId}}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation updateBulletin($id: Int!, $push_notification: Boolean!, $content: String!, $title: String!, $objects: [bulletin_files_insert_input!]!, $categoryId: Int!, $publish_at: timestamptz!) {\n  update_bulletin(where: {id: {_eq: $id}}, _set: {content: $content, title: $title, push_notification: $push_notification, category_id: $categoryId, publish_at: $publish_at}) {\n    affected_rows\n  }\n  delete_bulletin_files(where: {bulletin_id: {_eq: $id}}) {\n    affected_rows\n  }\n  insert_bulletin_files(objects: $objects) {\n    affected_rows\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation bulletinMutation($content: String!, $push_notification: Boolean!, $title: String!, $attach_files: [bulletin_files_insert_input!]!, $categoryId: Int!, $publish_at: timestamptz) {\n  insert_bulletin_one(object: {content: $content, push_notification: $push_notification, title: $title, publish_at: $publish_at, attach_files: {data: $attach_files}, category_id: $categoryId}) {\n    id\n  }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var ADD_BULLETIN = (0, _graphqlTag.default)(_templateObject());
exports.ADD_BULLETIN = ADD_BULLETIN;
var UPDATE_BULLETIN = (0, _graphqlTag.default)(_templateObject2());
exports.UPDATE_BULLETIN = UPDATE_BULLETIN;
var UPDATE_BULLETIN_BY_ID = (0, _graphqlTag.default)(_templateObject3());
exports.UPDATE_BULLETIN_BY_ID = UPDATE_BULLETIN_BY_ID;
var DELETE_SURVEY = (0, _graphqlTag.default)(_templateObject4());
exports.DELETE_SURVEY = DELETE_SURVEY;
var ADD_BULLETIN_CATEGORIES = (0, _graphqlTag.default)(_templateObject5());
exports.ADD_BULLETIN_CATEGORIES = ADD_BULLETIN_CATEGORIES;
var UPDATE_BULLETIN_CATEGORIES_BY_ID = (0, _graphqlTag.default)(_templateObject6());
exports.UPDATE_BULLETIN_CATEGORIES_BY_ID = UPDATE_BULLETIN_CATEGORIES_BY_ID;
var INSERT_SURVEY_ONE = (0, _graphqlTag.default)(_templateObject7());
exports.INSERT_SURVEY_ONE = INSERT_SURVEY_ONE;
var INSERT_BULLETIN_SURVEY_ONE = (0, _graphqlTag.default)(_templateObject8());
exports.INSERT_BULLETIN_SURVEY_ONE = INSERT_BULLETIN_SURVEY_ONE;
var DELETE_CATEGORY_AND_UPDATE_BULLETIN_CATEGORY_BY_ID = (0, _graphqlTag.default)(_templateObject9());
exports.DELETE_CATEGORY_AND_UPDATE_BULLETIN_CATEGORY_BY_ID = DELETE_CATEGORY_AND_UPDATE_BULLETIN_CATEGORY_BY_ID;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TitleWidget", { attrs: { title: "掲示板投稿" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "top",
                "label-width": "250px",
                model: _vm.form,
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 12, lg: 12, xl: 12, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "タイトル",
                            prop: "title",
                            rules: {
                              required: true,
                              validator: _vm.validateMaxLength,
                              colname: "タイトル",
                              maxLen: 40
                            }
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { "show-word-limit": "", maxlength: "40" },
                            model: {
                              value: _vm.form.title,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "title", $$v)
                              },
                              expression: "form.title"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 20, lg: 20, xl: 20, sm: 20, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "内容",
                            prop: "content",
                            rules: {
                              required: true,
                              validator: _vm.validateMaxLength,
                              colname: "内容",
                              maxLen: 1000
                            }
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: "10",
                              "show-word-limit": "",
                              maxlength: "1000"
                            },
                            model: {
                              value: _vm.form.content,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "content", $$v)
                              },
                              expression: "form.content"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 12, lg: 12, xl: 12, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "upload",
                          attrs: {
                            label: "添付ファイル",
                            prop: "fileList",
                            rules: {
                              required: false,
                              validator: _vm.validateFiles,
                              colname: "ファイル"
                            }
                          }
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload",
                              attrs: {
                                drag: "",
                                "on-remove": _vm.handleRemove,
                                "on-change": _vm.handleChange,
                                "on-exceed": _vm.handleExceed,
                                accept: "",
                                limit: 5,
                                action: "",
                                "file-list": _vm.form.fileList,
                                multiple: "",
                                "auto-upload": false
                              }
                            },
                            [
                              _c("div", [
                                _c("i", { staticClass: "el-icon-upload" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "el-upload__text" }, [
                                  _vm._v("ファイルをドロップする")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip"
                                  },
                                  [
                                    _c("i", [
                                      _vm._v("ファイルのサイズは20MB未満です。")
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 16, lg: 16, xl: 16, sm: 16, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "アンケート" } },
                        [
                          _c("Button", {
                            attrs: {
                              title: "アンケートを作成",
                              width: "126",
                              "is-loading": _vm.waitDialogVisible
                            },
                            on: { handleClick: _vm.createSurvey }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card",
                          attrs: { hidden: !_vm.form.showSurvey }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header"
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "question",
                                    rules: {
                                      required: false,
                                      validator: _vm.validateSurvey,
                                      colname: "質問"
                                    },
                                    error: ""
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: "5",
                                      placeholder:
                                        "アンケートの設問を入力してください"
                                    },
                                    model: {
                                      value: _vm.form.survey.question,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.survey,
                                          "question",
                                          $$v
                                        )
                                      },
                                      expression: "form.survey.question"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    color: "#606266"
                                  }
                                },
                                [_vm._v("回答方式を選択してください")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 0 },
                                      on: {
                                        click: function($event) {
                                          _vm.form.survey.radio += 0
                                        }
                                      },
                                      model: {
                                        value: _vm.form.survey.radio,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.survey,
                                            "radio",
                                            $$v
                                          )
                                        },
                                        expression: "form.survey.radio"
                                      }
                                    },
                                    [_vm._v("単一回答")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 1 },
                                      on: {
                                        click: function($event) {
                                          _vm.form.survey.radio += 1
                                        }
                                      },
                                      model: {
                                        value: _vm.form.survey.radio,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.survey,
                                            "radio",
                                            $$v
                                          )
                                        },
                                        expression: "form.survey.radio"
                                      }
                                    },
                                    [_vm._v("複数回答可")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    color: "#606266"
                                  }
                                },
                                [_vm._v("投票結果")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: true },
                                      on: {
                                        click: function($event) {
                                          _vm.form.survey.is_public_result += true
                                        }
                                      },
                                      model: {
                                        value: _vm.form.survey.is_public_result,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.survey,
                                            "is_public_result",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.survey.is_public_result"
                                      }
                                    },
                                    [_vm._v("公開する")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: false },
                                      on: {
                                        click: function($event) {
                                          _vm.form.survey.is_public_result += false
                                        }
                                      },
                                      model: {
                                        value: _vm.form.survey.is_public_result,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.survey,
                                            "is_public_result",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.survey.is_public_result"
                                      }
                                    },
                                    [_vm._v("公開しない")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-top": "12px" } },
                            [
                              _vm.form.survey.questions &&
                              _vm.form.survey.questions.length !== 0
                                ? _c(
                                    "div",
                                    _vm._l(_vm.form.survey.questions, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-row",
                                        { key: index },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 22 } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "grid-content bg-purple"
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        error: item.errorSurvey
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-input",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "設問に対しての選択肢を入力"
                                                          },
                                                          model: {
                                                            value: item.content,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "content",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.content"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "line-height":
                                                                  "2.5",
                                                                "margin-left":
                                                                  "8px"
                                                              },
                                                              attrs: {
                                                                slot: "prefix"
                                                              },
                                                              slot: "prefix"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  index + 1
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("el-col", { attrs: { span: 2 } }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid-content bg-purple-light",
                                                staticStyle: {
                                                  "line-height": "2.3",
                                                  "margin-left": "8px"
                                                }
                                              },
                                              [
                                                index > 1
                                                  ? _c("span", {
                                                      staticClass:
                                                        "el-icon-circle-close",
                                                      staticStyle: {
                                                        color: "gray"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteSelection(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    "font-size": "14px"
                                  },
                                  on: { click: _vm.addSelection }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _vm._v("選択項目を追加")
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    size: "small",
                                    plain: ""
                                  },
                                  on: { click: _vm.handleDeleteItem }
                                },
                                [_vm._v("キャンセル")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 16, lg: 16, xl: 16, sm: 16, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "カテゴリー" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "カテゴリーを選択" },
                              model: {
                                value: _vm.form.categoryId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "categoryId", $$v)
                                },
                                expression: "form.categoryId"
                              }
                            },
                            _vm._l(_vm.bulletinCategories, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 6, lg: 6, xl: 6, sm: 6, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "publish_at",
                            label: "アプリへの公開／下書き",
                            rules: {
                              required: true,
                              validator: _vm.validateSelectBox,
                              colname: "状態"
                            }
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "選択してください" },
                              model: {
                                value: _vm.form.publish_at,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "publish_at", $$v)
                                },
                                expression: "form.publish_at"
                              }
                            },
                            _vm._l(_vm.situation, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.val }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("Button", {
                attrs: {
                  title: "投稿",
                  width: "140",
                  "is-loading": _vm.waitDialogVisible
                },
                on: { handleClick: _vm.postFromCheck }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
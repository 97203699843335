"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_COMMENT_BY_ID = exports.DELETE_COMMENT_BY_ID = exports.INSERT_SUGGESTION_COMMENT = exports.DELETE_CATEGORY_AND_UPDATE_SUGGESTION_CATEGORY_BY_ID = exports.UPDATE_SUGGESTION_CATEGORIES_BY_ID = exports.ADD_SUGGESTION_CATEGORIES = exports.ADD_SUGGESTION_USER = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation update_suggestion_comments_by_pk($commentId: Int!, $comment: String!) {\n  update_suggestion_comments_by_pk(pk_columns: {id: $commentId}, _set: {comment: $comment}) {\n    id\n  }\n}\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation delete_suggestion_comments_by_pk($comment_id: Int!) {\n  delete_suggestion_comments_by_pk(id: $comment_id) {\n    id\n  }\n}\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation insert_suggestion_comments($comment: String!, $suggestion_id: Int!) {\n  insert_suggestion_comments(objects: {comment: $comment, suggestion_id: $suggestion_id}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteCategoryAndUpdateSuggestionCategory($category_id: Int!) {\n  update_suggestion(where: {category_id: {_eq: $category_id}}, _set: {category_id: null}) {\n    affected_rows\n  }\n  delete_suggestion_categories_by_pk(id: $category_id) {\n    id\n  }\n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation update_suggestion_categories($id: Int!, $name: String!) {\n  update_suggestion_categories_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {\n    id\n  }\n}\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation insertSuggestionCategories($name: String!) {\n  insert_suggestion_categories_one(object: {name: $name}) {\n    id\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2.default)(["\nmutation addSuggestionUser($suggestion_id: Int!) {\n    insert_suggestion_users_one(object: {suggestion_id: $suggestion_id}, on_conflict: {constraint: suggestion_users_suggestion_id_user_id_key, update_columns: updated_at}) {\n      id\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var ADD_SUGGESTION_USER = (0, _graphqlTag.default)(_templateObject());
exports.ADD_SUGGESTION_USER = ADD_SUGGESTION_USER;
var ADD_SUGGESTION_CATEGORIES = (0, _graphqlTag.default)(_templateObject2());
exports.ADD_SUGGESTION_CATEGORIES = ADD_SUGGESTION_CATEGORIES;
var UPDATE_SUGGESTION_CATEGORIES_BY_ID = (0, _graphqlTag.default)(_templateObject3());
exports.UPDATE_SUGGESTION_CATEGORIES_BY_ID = UPDATE_SUGGESTION_CATEGORIES_BY_ID;
var DELETE_CATEGORY_AND_UPDATE_SUGGESTION_CATEGORY_BY_ID = (0, _graphqlTag.default)(_templateObject4());
exports.DELETE_CATEGORY_AND_UPDATE_SUGGESTION_CATEGORY_BY_ID = DELETE_CATEGORY_AND_UPDATE_SUGGESTION_CATEGORY_BY_ID;
var INSERT_SUGGESTION_COMMENT = (0, _graphqlTag.default)(_templateObject5());
exports.INSERT_SUGGESTION_COMMENT = INSERT_SUGGESTION_COMMENT;
var DELETE_COMMENT_BY_ID = (0, _graphqlTag.default)(_templateObject6());
exports.DELETE_COMMENT_BY_ID = DELETE_COMMENT_BY_ID;
var UPDATE_COMMENT_BY_ID = (0, _graphqlTag.default)(_templateObject7());
exports.UPDATE_COMMENT_BY_ID = UPDATE_COMMENT_BY_ID;
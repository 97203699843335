"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = isExternal;
exports.validUsername = validUsername;
exports.validURL = validURL;
exports.validLowerCase = validLowerCase;
exports.validUpperCase = validUpperCase;
exports.validAlphabets = validAlphabets;
exports.validEmail = validEmail;
exports.isString = isString;
exports.isArray = isArray;
exports.depthFirstSearch = depthFirstSearch;
exports.switchTitle = switchTitle;
exports.checkIsNumber = checkIsNumber;
exports.checkIsNumberAndComma = checkIsNumberAndComma;
exports.extractExtAndNameFromFilename = extractExtAndNameFromFilename;
exports.mobileRegex = exports.emailRegex = exports.urlRegex = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.set");

var _toConsumableArray2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.to-string");

var _auth = require("../utils/auth");

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * ログイン時のユーザ名はメールアドレスのみ
 * @param {string} str
 * @returns {Boolean}
 */


function validUsername(str) {
  return str.trim().length > 0;
}
/**
 * @param {string} url
 * @returns {Boolean}
 */


function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}
/**
 * @param {string} email
 * @returns {Boolean}
 */


function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }

  return false;
}
/**
 * @param {Array} arg
 * @returns {Boolean}
 */


function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }

  return Array.isArray(arg);
}

function depthFirstSearch(affiliations, affiliation, optionId) {
  var result = [];
  var treeMapAffiliation = [];

  function indexWhere(array, conditionFn) {
    var item = array.find(conditionFn);
    return array.indexOf(item);
  }

  affiliations.map(function (item) {
    return affiliationSearch(item);
  });

  function affiliationSearch(object) {
    if (treeMapAffiliation) {
      var indexOfTreeAffiliation = indexWhere(treeMapAffiliation, function (item) {
        return item.parent_id === object.parent_id;
      });

      if (indexOfTreeAffiliation >= 0) {
        treeMapAffiliation.splice(indexOfTreeAffiliation, treeMapAffiliation.length);
      }
    }

    treeMapAffiliation.push(object);

    if (affiliation.length && affiliation) {
      var checkNotEmpty = affiliation.filter(function (item) {
        return item.id.toString() === object.id.toString();
      });

      if (checkNotEmpty.length) {
        treeMapAffiliation.forEach(function (item) {
          var indexOfFloor = treeMapAffiliation.indexOf(item);
          item.paddingFloorInTreeAffiliation = indexOfFloor * 24;

          if (item.id === checkNotEmpty[0].id) {
            item.is_directly = true;
          }

          result.push(item);
        });
      }
    }

    return object.affiliations.map(function (ite) {
      return affiliationSearch(ite);
    });
  }

  return (0, _toConsumableArray2.default)(new Set(result));
} // switch text by zone id


function switchTitle() {
  var zoneId = (0, _auth.getZoneId)().trim(); // zone 1 -> hiroshima, zone 2 -> osaka

  switch (zoneId) {
    case '1':
      return {
        textBranchAreaByZone: '所属グループ',
        branchMassage: '支部',
        areaMessage: '地区',
        importSample: 'assets/import_sample_hiroshima.xlsx',
        branchName: '広島',
        exampleName: '広島東支部の広島東支部理事会(1103)',
        exampleNumber: '1064, 1103'
      };

    case '2':
      return {
        textBranchAreaByZone: 'ブロック&支部',
        branchMassage: 'ブロック',
        areaMessage: '支部',
        importSample: 'assets/import_sample.xlsx',
        branchName: '大阪',
        exampleName: '組織委員会(2150)',
        exampleNumber: '2067, 2150'
      };

    default:
      return {
        textBranchAreaByZone: '所属グループ',
        branchMassage: '支部',
        areaMessage: '地区',
        importSample: 'assets/import_sample_hiroshima.xlsx',
        branchName: '大阪',
        exampleName: '広島東支部の広島東支部理事会(1103)',
        exampleNumber: '1077, 1064, 1103'
      };
  }
} // check is number


function checkIsNumber(value) {
  var regNumber = /^[0-9]+$/;

  try {
    if (regNumber.test(Number(value))) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

function checkIsNumberAndComma(value) {
  var regNumber = /^[0-9.,]+$/;

  try {
    if (regNumber.test(value)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

function extractExtAndNameFromFilename(fileName) {
  var extractFile = {
    nameFile: '',
    extension: ''
  };

  try {
    var file_name_array = fileName.split('.');
    extractFile.nameFile = file_name_array.slice(0, -1).join('.');
    extractFile.extension = file_name_array.pop();
    return extractFile;
  } catch (_unused) {
    return extractFile;
  }
}

var urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#!=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#!?&//=]*)/g;
exports.urlRegex = urlRegex;
var emailRegex = /([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/ig;
exports.emailRegex = emailRegex;
var mobileRegex = /\s+((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})/g;
exports.mobileRegex = mobileRegex;
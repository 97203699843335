"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    pageCount: {
      type: [Number],
      default: null,
      require: false
    },
    pageTotal: {
      type: [Number],
      default: null,
      require: false
    }
  }
};
exports.default = _default;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "text-align": "center" },
              attrs: { md: 2, lg: 2, sm: 2, xs: 4 }
            },
            [
              _c(
                "div",
                { staticClass: "avatar" },
                [
                  _c("el-avatar", { attrs: { size: 46 } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.getMyAvatar
                          ? _vm.getMyAvatar
                          : "parson_no-image.png"
                      }
                    })
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { "padding-top": "4px" },
              attrs: { md: 14, lg: 14, sm: 16, xs: 14 }
            },
            [
              _c(
                "div",
                { staticClass: "input" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.form, rules: _vm.rules }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c("el-input", {
                            attrs: {
                              autosize: { minRows: 1, maxRows: 16 },
                              type: "textarea",
                              placeholder: "コメントを追加..."
                            },
                            model: {
                              value: _vm.form.comment,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "comment", $$v)
                              },
                              expression: "form.comment"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { padding: "8px 8px" },
              attrs: { md: 4, lg: 4, sm: 4, xs: 4 }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "background-color": "#0B5BAC",
                        color: "#fff"
                      },
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.insertComment()
                        }
                      }
                    },
                    [_vm._v("コメント")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
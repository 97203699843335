exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#000",
	"menuActiveText": "#0B5BAC",
	"subMenuActiveText": "#0B5BAC",
	"menuBg": "#F4F3EF",
	"menuHover": "#9d9e9e",
	"subMenuBg": "#e6e5e5",
	"subMenuHover": "#9d9e9e",
	"sideBarWidth": "210px"
};
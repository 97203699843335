var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "background" },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "h3",
          { staticClass: "title-page" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "info", plain: "", size: "mini" },
                on: { click: _vm.goToIndex }
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" 権限追加")
          ],
          1
        ),
        _vm._v(" "),
        _c("hr", { staticClass: "divider" }),
        _vm._v(" "),
        _c("div", { staticClass: "title-tab" }, [_vm._v("権限情報")]),
        _vm._v(" "),
        _c("br")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 8, lg: 8, xl: 8, sm: 8, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "ユーザーの役割",
                            prop: "roleName",
                            rules: {
                              required: true,
                              validator: _vm.validateMaxLength,
                              colname: "ユーザーの役割名",
                              maxLen: 40
                            }
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "ユーザーの役割名"
                            },
                            model: {
                              value: _vm.form.roleName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "roleName", $$v)
                              },
                              expression: "form.roleName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { md: 10, lg: 10, xl: 10, sm: 10, xs: 24 } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.roleApp, stripe: "", border: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "30",
                              align: "center",
                              label: "アプリ"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("div", { attrs: { align: "left" } }, [
                                      _vm._v(_vm._s(scope.row.name))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { "min-width": "10", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-switch", {
                                      model: {
                                        value: scope.row.val,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "val", $$v)
                                        },
                                        expression: "scope.row.val"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { md: 10, lg: 10, xl: 10, sm: 10, xs: 24 } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.roleAdmin, stripe: "", border: "" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "30",
                              align: "center",
                              label: "管理サイト"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("div", { attrs: { align: "left" } }, [
                                      _vm._v(_vm._s(scope.row.name))
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { "min-width": "10", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-switch", {
                                      model: {
                                        value: scope.row.val,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "val", $$v)
                                        },
                                        expression: "scope.row.val"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("Button", {
        attrs: {
          title: "登録",
          width: "140",
          "is-loading": _vm.waitDialogVisible
        },
        on: {
          handleClick: function($event) {
            return _vm.handleDialogValidate()
          }
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "background" },
    [
      _vm.role.admin_role_add
        ? _c(
            "div",
            { staticClass: "createBT" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "info", plain: "" },
                  on: { click: _vm.goToCreate }
                },
                [_vm._v("役割を追加")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              staticClass: "roleTable",
              attrs: { border: "", data: _vm.userRoles }
            },
            [
              _c("el-table-column", {
                staticClass: "buttons",
                attrs: {
                  align: "center",
                  "min-width": "240px",
                  label: "役割リスト"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { attrs: { align: "left" } }, [
                          _vm._v(_vm._s(scope.row.name))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                staticClass: "buttons",
                attrs: { align: "center", width: "100px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "button-first colorButton",
                            attrs: { size: "small", type: "info", plain: "" },
                            on: {
                              click: function($event) {
                                return _vm.goToDetail(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.role.admin_role_edit ? "編集" : "詳細")
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkExt = checkExt;
exports.checkExtImage = checkExtImage;
exports.checkExtExcel = checkExtExcel;

// ファイル名から拡張子を取得する関数
function getExt(filename) {
  var pos = filename.lastIndexOf('.');
  if (pos === -1) return '';
  return filename.slice(pos + 1);
} // アップロード予定のファイル名の拡張子が許可されているか確認する関数


function checkExt(filename) {
  var allow_exts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  // 比較のため小文字にする
  var ext = getExt(filename).toLowerCase(); // 許可する拡張子の一覧(allow_exts)から対象の拡張子があるか確認する

  if (allow_exts.indexOf(ext) === -1) return false;
  return true;
} // 画像拡張子のチェック関数


var image_exts = ['jpg', 'jpeg', 'png'];

function checkExtImage(filename) {
  return checkExt(filename, image_exts);
} // エクセルファイルのチェック関数


var excel_exts = ['xlsx'];

function checkExtExcel(filename) {
  return checkExt(filename, excel_exts);
}
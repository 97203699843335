"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dropdownEnum = exports.allAdminPermissionAndRouter = exports.pageEnum = exports.fileTypeEnum = void 0;
var fileTypeEnum = {
  Image: 1,
  File: 3
};
exports.fileTypeEnum = fileTypeEnum;
var pageEnum = {
  IndexPage: 1,
  CreatePage: 2,
  EditPage: 3
};
exports.pageEnum = pageEnum;
var allAdminPermissionAndRouter = [{
  nameData: 'admin_user_view',
  pathNames: ['CompanyIndex', 'CompanyAdminEdit']
}, {
  nameData: 'admin_user_add',
  pathNames: ['CompanyCreate']
}, {
  nameData: 'admin_user_import',
  pathNames: ['CompanyImport']
}, {
  nameData: 'admin_role_view',
  pathNames: ['RoleIndex', 'EditRole']
}, {
  nameData: 'admin_role_add',
  pathNames: ['CreateRole']
}, {
  nameData: 'admin_bulletin_view',
  pathNames: ['BulletinIndex', 'BulletinEdit']
}, {
  nameData: 'admin_bulletin_add',
  pathNames: ['BulletinCreate']
}, {
  nameData: 'admin_bulletin_category_edit',
  pathNames: ['CategoryIndex']
}, {
  nameData: 'admin_suggestion_view',
  pathNames: ['SuggestionIndex', 'SuggestionDetail']
}, {
  nameData: 'admin_suggestion_category_edit',
  pathNames: ['SuggestionCategory']
}];
exports.allAdminPermissionAndRouter = allAdminPermissionAndRouter;
var dropdownEnum = {
  Edit: '編集',
  Delete: '削除'
};
exports.dropdownEnum = dropdownEnum;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "vue-loadmore",
            {
              attrs: {
                "on-loadmore": _vm.onLoad,
                "head-height": "0",
                "loading-text": " "
              }
            },
            _vm._l(_vm.comments, function(item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "el-row",
                    { staticClass: "commentBoxItem" },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { md: 2, lg: 2, sm: 2, xs: 4 }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar" },
                            [
                              _c(
                                "el-avatar",
                                { attrs: { size: 46, src: "https://empty" } },
                                [_c("img", { attrs: { src: item.avatar } })]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { md: 20, lg: 20, sm: 20, xs: 18 } },
                        [
                          _c("div", { staticClass: "fullName" }, [
                            _c(
                              "div",
                              { staticStyle: { "padding-bottom": "8px" } },
                              [
                                _c("span", [_vm._v(_vm._s(item.fullName))]),
                                _vm._v(" "),
                                _c("span", { staticStyle: { color: "gray" } }, [
                                  _vm._v(" " + _vm._s(item.comment_time)),
                                  item.created_at !== item.updated_at
                                    ? _c("span", [_vm._v(" (編集済み)")])
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.form.editIndex === item.id
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "comment", label: "" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            autosize: {
                                              minRows: 1,
                                              maxRows: 16
                                            },
                                            type: "textarea",
                                            placeholder: "コメントを追加..."
                                          },
                                          model: {
                                            value: _vm.form.comment,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "comment", $$v)
                                            },
                                            expression: "form.comment"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "padding-top": "8px",
                                          "text-align": "right"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { width: "88px" },
                                            attrs: { size: "small" },
                                            on: {
                                              click: function($event) {
                                                _vm.form.editIndex = null
                                              }
                                            }
                                          },
                                          [_vm._v("キャンセル")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              width: "88px",
                                              "background-color": "#0B5BAC",
                                              color: "#fff"
                                            },
                                            attrs: { size: "small" },
                                            on: {
                                              click: function($event) {
                                                return _vm.updateComment({
                                                  commentId: item.id,
                                                  comment: _vm.form.comment
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v("更新\n                  ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "linkify",
                                        rawName: "v-linkify",
                                        value: {
                                          className: "linkClass",
                                          target: "_blank"
                                        },
                                        expression:
                                          "{ className: 'linkClass', target: '_blank' }"
                                      }
                                    ],
                                    staticStyle: {
                                      "white-space": "pre-line",
                                      color: "#606266",
                                      "line-height": "1.6",
                                      "word-wrap": "break-word"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(item.comment)
                                    }
                                  })
                                ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.form.user_uid === item.user_uid
                        ? _c(
                            "el-col",
                            { attrs: { md: 2, lg: 2, sm: 2, xs: 2 } },
                            [
                              _c(
                                "el-dropdown",
                                { on: { command: _vm.handleCommand } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "el-dropdown-link",
                                      staticStyle: { cursor: "pointer" }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "icons/menu-vertical.png",
                                          alt: "",
                                          width: "20"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          staticStyle: { width: "90px" },
                                          attrs: {
                                            command: {
                                              mode: _vm.dropEnum.Edit,
                                              id: item.id,
                                              comment: item.comment
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-edit"
                                          }),
                                          _vm._v(" 編集")
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          staticStyle: { width: "90px" },
                                          attrs: {
                                            command: {
                                              mode: _vm.dropEnum.Delete,
                                              id: item.id
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete"
                                          }),
                                          _vm._v(" 削除")
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }